import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const VideoCameraEmphasisIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg
      style={{ overflow: 'visible' }}
      role="presentation"
      focusable="false"
      viewBox="2 2 16 16"
      className={classes.svg}
    >
      <g>
        <g className={cx(iconClassNames.outline, classes.outlinePart)}>
          <path d="M5 5C3.89543 5 3 5.89543 3 7V13C3 14.1046 3.89543 15 5 15H11C12.1046 15 13 14.1046 13 13V11.9713L15.8413 13.8182C16.3402 14.1425 17 13.7844 17 13.1893V6.81097C17 6.21571 16.3398 5.85768 15.8409 6.18236L13 8.03118V7C13 5.89543 12.1046 5 11 5H5ZM13 9.22429L16 7.27195V12.7287L13 10.7787V9.22429ZM12 7V13C12 13.5523 11.5523 14 11 14H5C4.44772 14 4 13.5523 4 13V7C4 6.44772 4.44772 6 5 6H11C11.5523 6 12 6.44772 12 7Z" />
          <path d="M6.89163 2.0301C6.5251 2.16339 6.23931 2.29759 6.04215 2.40046C5.94354 2.45191 5.86697 2.49558 5.81343 2.52748C5.78666 2.54343 5.76563 2.55645 5.75046 2.56603L5.73212 2.57776L5.72627 2.58158L5.72418 2.58296L5.72265 2.58397C5.72248 2.58409 5.72265 2.58397 6 3L5.72265 2.58397C5.49289 2.73715 5.4308 3.04759 5.58398 3.27735C5.73709 3.50702 6.04691 3.56943 6.27664 3.4165L6.28445 3.41151C6.29248 3.40644 6.30612 3.39797 6.32524 3.38658C6.3635 3.36379 6.42365 3.32934 6.50472 3.28704C6.66694 3.20241 6.9124 3.08661 7.23337 2.9699C7.87525 2.73649 8.81727 2.5 10 2.5C11.1827 2.5 12.1247 2.73649 12.7666 2.9699C13.0876 3.08661 13.3331 3.20241 13.4953 3.28704C13.5764 3.32934 13.6365 3.36379 13.6748 3.38658C13.6939 3.39797 13.7075 3.40644 13.7155 3.41151L13.7233 3.41646C13.7232 3.41637 13.7227 3.41603 13.9848 3.02283L13.7233 3.41646C13.953 3.56908 14.263 3.5069 14.416 3.27735C14.5692 3.04759 14.5064 2.73669 14.2767 2.58351L14 3C14.2774 2.58397 14.2768 2.58362 14.2767 2.58351L14.2758 2.58296L14.2737 2.58158L14.2679 2.57776L14.2495 2.56603C14.2344 2.55645 14.2133 2.54343 14.1866 2.52748C14.133 2.49558 14.0565 2.45191 13.9578 2.40046C13.7607 2.29759 13.4749 2.16339 13.1084 2.0301C12.3753 1.76351 11.3173 1.5 10 1.5C8.68273 1.5 7.62475 1.76351 6.89163 2.0301ZM6.27664 3.4165C6.27605 3.41689 6.27652 3.41658 6.27664 3.4165V3.4165ZM13.7233 3.41646C13.7232 3.41639 13.7234 3.41653 13.7233 3.41646V3.41646Z" />
          <path d="M6.89163 17.9699C7.62475 18.2365 8.68273 18.5 10 18.5C11.3173 18.5 12.3753 18.2365 13.1084 17.9699C13.4749 17.8366 13.7607 17.7024 13.9578 17.5995C14.0565 17.5481 14.133 17.5044 14.1866 17.4725C14.2133 17.4566 14.2344 17.4436 14.2495 17.434L14.2679 17.4222L14.2737 17.4184L14.2758 17.417L14.2767 17.4165C14.2768 17.4164 14.2774 17.416 14 17L14.2767 17.4165C14.5064 17.2633 14.5692 16.9524 14.416 16.7226C14.263 16.4931 13.953 16.4309 13.7233 16.5835L13.9848 16.9772C13.7227 16.584 13.7232 16.5836 13.7233 16.5835L13.7155 16.5885C13.7075 16.5936 13.6939 16.602 13.6748 16.6134C13.6365 16.6362 13.5764 16.6707 13.4953 16.713C13.3331 16.7976 13.0876 16.9134 12.7666 17.0301C12.1247 17.2635 11.1827 17.5 10 17.5C8.81727 17.5 7.87525 17.2635 7.23337 17.0301C6.9124 16.9134 6.66694 16.7976 6.50472 16.713C6.42365 16.6707 6.3635 16.6362 6.32524 16.6134C6.30612 16.602 6.29248 16.5936 6.28445 16.5885L6.27664 16.5835C6.04691 16.4306 5.73709 16.493 5.58398 16.7226C5.4308 16.9524 5.49289 17.2628 5.72265 17.416L6 17C5.72265 17.416 5.72248 17.4159 5.72265 17.416L5.72418 17.417L5.72627 17.4184L5.73212 17.4222L5.75046 17.434C5.76563 17.4436 5.78666 17.4566 5.81343 17.4725C5.86697 17.5044 5.94354 17.5481 6.04215 17.5995C6.23931 17.7024 6.5251 17.8366 6.89163 17.9699ZM6.27664 16.5835C6.27605 16.5831 6.27652 16.5834 6.27664 16.5835V16.5835ZM13.7233 16.5835C13.7232 16.5836 13.7234 16.5835 13.7233 16.5835V16.5835Z" />
        </g>

        <g className={cx(iconClassNames.filled, classes.filledPart)}>
          <path d="M6.89163 2.0301C6.5251 2.16339 6.23931 2.29759 6.04215 2.40046C5.94354 2.45191 5.86697 2.49558 5.81343 2.52748C5.78666 2.54343 5.76563 2.55645 5.75046 2.56603L5.73212 2.57776L5.72627 2.58158L5.72418 2.58296L5.72265 2.58397C5.72248 2.58409 5.72265 2.58397 6 3L5.72265 2.58397C5.49289 2.73715 5.4308 3.04759 5.58398 3.27735C5.73709 3.50702 6.04691 3.56943 6.27664 3.4165L6.28445 3.41151C6.29248 3.40644 6.30612 3.39797 6.32524 3.38658C6.3635 3.36379 6.42365 3.32934 6.50472 3.28704C6.66694 3.20241 6.9124 3.08661 7.23337 2.9699C7.87525 2.73649 8.81727 2.5 10 2.5C11.1827 2.5 12.1247 2.73649 12.7666 2.9699C13.0876 3.08661 13.3331 3.20241 13.4953 3.28704C13.5764 3.32934 13.6365 3.36379 13.6748 3.38658C13.6939 3.39797 13.7075 3.40644 13.7155 3.41151L13.7233 3.41646C13.7232 3.41637 13.7227 3.41603 13.9848 3.02283L13.7233 3.41646C13.953 3.56908 14.263 3.5069 14.416 3.27735C14.5692 3.04759 14.5064 2.73669 14.2767 2.58351L14 3C14.2774 2.58397 14.2768 2.58362 14.2767 2.58351L14.2758 2.58296L14.2737 2.58158L14.2679 2.57776L14.2495 2.56603C14.2344 2.55645 14.2133 2.54343 14.1866 2.52748C14.133 2.49558 14.0565 2.45191 13.9578 2.40046C13.7607 2.29759 13.4749 2.16339 13.1084 2.0301C12.3753 1.76351 11.3173 1.5 10 1.5C8.68273 1.5 7.62475 1.76351 6.89163 2.0301ZM6.27664 3.4165C6.27605 3.41689 6.27652 3.41658 6.27664 3.4165V3.4165ZM13.7233 3.41646C13.7232 3.41639 13.7234 3.41653 13.7233 3.41646V3.41646Z" />
          <path d="M6.89163 17.9699C7.62475 18.2365 8.68273 18.5 10 18.5C11.3173 18.5 12.3753 18.2365 13.1084 17.9699C13.4749 17.8366 13.7607 17.7024 13.9578 17.5995C14.0565 17.5481 14.133 17.5044 14.1866 17.4725C14.2133 17.4566 14.2344 17.4436 14.2495 17.434L14.2679 17.4222L14.2737 17.4184L14.2758 17.417L14.2767 17.4165C14.2768 17.4164 14.2774 17.416 14 17L14.2767 17.4165C14.5064 17.2633 14.5692 16.9524 14.416 16.7226C14.263 16.4931 13.953 16.4309 13.7233 16.5835L13.9848 16.9772C13.7227 16.584 13.7232 16.5836 13.7233 16.5835L13.7155 16.5885C13.7075 16.5936 13.6939 16.602 13.6748 16.6134C13.6365 16.6362 13.5764 16.6707 13.4953 16.713C13.3331 16.7976 13.0876 16.9134 12.7666 17.0301C12.1247 17.2635 11.1827 17.5 10 17.5C8.81727 17.5 7.87525 17.2635 7.23337 17.0301C6.9124 16.9134 6.66694 16.7976 6.50472 16.713C6.42365 16.6707 6.3635 16.6362 6.32524 16.6134C6.30612 16.602 6.29248 16.5936 6.28445 16.5885L6.27664 16.5835C6.04691 16.4306 5.73709 16.493 5.58398 16.7226C5.4308 16.9524 5.49289 17.2628 5.72265 17.416L6 17C5.72265 17.416 5.72248 17.4159 5.72265 17.416L5.72418 17.417L5.72627 17.4184L5.73212 17.4222L5.75046 17.434C5.76563 17.4436 5.78666 17.4566 5.81343 17.4725C5.86697 17.5044 5.94354 17.5481 6.04215 17.5995C6.23931 17.7024 6.5251 17.8366 6.89163 17.9699ZM6.27664 16.5835C6.27605 16.5831 6.27652 16.5834 6.27664 16.5835V16.5835ZM13.7233 16.5835C13.7232 16.5836 13.7234 16.5835 13.7233 16.5835V16.5835Z" />
          <path d="M2.5 7V13C2.5 14.1046 3.39543 15 4.5 15H10.5C11.6046 15 12.5 14.1046 12.5 13V7C12.5 5.89543 11.6046 5 10.5 5H4.5C3.39543 5 2.5 5.89543 2.5 7Z" />
          <path d="M13.5 11.5505L16.2771 13.8068C16.7672 14.2051 17.5 13.8563 17.5 13.2248V6.77524C17.5 6.14372 16.7672 5.79492 16.2771 6.19316L13.5 8.44952V11.5505Z" />
        </g>
      </g>
    </svg>
  ),
  displayName: 'VideoCameraEmphasisIcon',
});
