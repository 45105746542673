import * as React from 'react';
import cx from 'classnames';
import { createSvgIcon } from '../utils/createSvgIcon';
import { iconClassNames } from '../utils/iconClassNames';

export const CallEndIcon = createSvgIcon({
  svg: ({ classes }) => (
    <svg role="presentation" focusable="false" className={classes.svg} viewBox="2 2 16 16">
      <path
        className={cx(iconClassNames.outline, classes.outlinePart)}
        d="M10 6.00001C13.3317 5.99572 15.5858 7.2756 17.154 8.65976C17.8139 9.2422 18.116 10.1179 17.9594 10.94L17.8015 11.7692C17.6535 12.5461 16.9272 13.0679 16.1042 12.9884L14.4666 12.8302C13.753 12.7613 13.2241 12.2401 13 11.5001C12.6957 10.4953 12.5 9.75009 12.5 9.75009C11.7522 9.44357 11.0138 9.25005 10 9.25005C8.98623 9.25005 8.26225 9.46492 7.5 9.75009C7.5 9.75009 7.29566 10.496 7 11.5001C6.80244 12.171 6.49595 12.7567 5.79708 12.8269L4.16895 12.9905C3.35656 13.0722 2.57765 12.5555 2.3467 11.7818L2.09921 10.9526C1.85286 10.1273 2.0727 9.25869 2.67633 8.67245C4.10141 7.28843 6.67315 6.00433 10 6.00001ZM13.4754 9.52689L13.5008 9.62157C13.5232 9.70474 13.5565 9.82664 13.5991 9.97972C13.6846 10.2861 13.8075 10.7163 13.9571 11.2102C14.0936 11.661 14.3462 11.8139 14.5628 11.8348L16.2004 11.993C16.5457 12.0264 16.7746 11.816 16.8191 11.5821L16.9771 10.7529C17.0653 10.2894 16.8963 9.76613 16.4923 9.40948C15.0673 8.1517 13.0404 6.9961 10.0013 7.00001C6.92594 7.00401 4.60599 8.19238 3.37303 9.38982C3.04458 9.70881 2.9145 10.1877 3.05744 10.6666L3.30493 11.4957C3.39647 11.8024 3.72575 12.03 4.06896 11.9955L5.69709 11.8319C5.73551 11.8281 5.74859 11.8203 5.7511 11.8189C5.75521 11.8165 5.77275 11.8057 5.80155 11.7692C5.8711 11.6811 5.9536 11.5135 6.04073 11.2176C6.18728 10.7199 6.31122 10.2861 6.39847 9.97694C6.44208 9.82241 6.47648 9.69914 6.49992 9.61474L6.52663 9.51827C6.62916 9.15132 6.98467 8.89552 7.14961 8.81349C7.96877 8.50703 8.81921 8.25005 10 8.25005C11.1653 8.25005 12.0327 8.47779 12.8793 8.8248C13.0313 8.88736 13.3617 9.10921 13.4672 9.4961L13.4691 9.50315L13.4754 9.52689Z"
      />
      <path
        className={cx(iconClassNames.filled, classes.filledPart)}
        d="M17.9594 10.94L17.8015 11.7691C17.6535 12.546 16.9272 13.0678 16.1042 12.9883L14.4666 12.8301C13.753 12.7612 13.2241 12.24 13 11.5C12.6957 10.4952 12.5 9.75 12.5 9.75C11.7522 9.44348 11.0138 9.24996 10 9.24996C8.98623 9.24996 8.26225 9.46483 7.5 9.75C7.5 9.75 7.29566 10.4959 7 11.5C6.80244 12.1709 6.49595 12.7566 5.79708 12.8268L4.16895 12.9904C3.35656 13.0721 2.57765 12.5554 2.3467 11.7817L2.09921 10.9525C1.85286 10.1272 2.0727 9.2586 2.67633 8.67236C4.10141 7.28834 6.6656 6.50821 9.99245 6.50389C13.3241 6.4996 15.5858 7.27551 17.154 8.65967C17.8139 9.24211 18.116 10.1178 17.9594 10.94Z"
      />
    </svg>
  ),
  displayName: 'CallEndIcon',
});
